import React from 'react'
import { Outlet } from 'react-router-dom'

//https://www.robinwieruch.de/react-router-private-routes/

const ProtectRoute = () => {
  return <Outlet />
}

export default ProtectRoute
