import React, { createContext, useState } from 'react'
import { Login, UserDataLogin } from '../types/User.Types'
import * as AuthService from '../services/Auth.Service'
import { useToast } from '../hooks/useToast'

interface IAuthContextType {
  user: UserDataLogin | null
  setUser: React.Dispatch<React.SetStateAction<UserDataLogin | null>>
  login: (data: Login) => Promise<UserDataLogin | null>
  logout: () => void
}

export const AuthContext = createContext<IAuthContextType | null>(null)

export const useUser = () => {
  const context = React.useContext(AuthContext)
  if (!context) throw new Error('useUser precisa estar em UserContextProvider')
  return context
}

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<UserDataLogin | null>(() => {
    const savedUser = localStorage.getItem('user')
    return savedUser ? JSON.parse(savedUser) : null
  })

  const toast = useToast()

  const login = async (dataSend: Login): Promise<UserDataLogin | null> => {
    const { error, data } = await AuthService.LoginUser(dataSend)

    if (error) {
      toast.toastWarn(error)
      return null
    }
    setUser(data)
    localStorage.setItem('user', JSON.stringify(data))
    return data
  }

  const logout = async () => {
    //enviar para API o logout.
    localStorage.removeItem('user')
    localStorage.removeItem('company')
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
