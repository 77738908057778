import axios from 'axios'
import qs from 'qs'

const getURLAPI = () => {
  if (process.env!.NODE_ENV === 'production') {
    return 'https://app-api.aptosistemas.com.br'
  } else if (process.env!.NODE_ENV === 'development') {
    // return 'http://localhost:4000'
    return 'http://192.168.0.17:4000'
  } else {
    //return 'http://localhost:4000'
    return 'http://192.168.0.17:4000'
  }
}

const api = axios.create({
  baseURL: getURLAPI(),
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus(status) {
    return status < 500
  },
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params)
    },
  },
})

api.interceptors.response.use((response) => {
  if ([401, 403].includes(response.status)) {
    localStorage.removeItem('user')
    localStorage.removeItem('company')

    if (
      !window.location.pathname.startsWith('/open') &&
      window.location.pathname !== '/auth/login'
    ) {
      window.location.href = `/auth/login?to=${window.location.pathname}`
    }

    return response
  } else return Promise.resolve(response)
})

export default api
