import { ApiGet, ApiPost } from '../hooks/useRequest'
import { Login, UserDataLogin } from '../types/User.Types'
import { Company, CompanyDataLogin } from '../types/Company.Types'

export const LoginUser = async (data: Login) => {
  return await ApiPost<UserDataLogin>('/auth/login', data)
}

export const GetCompanies = async (token?: string) => {
  return await ApiGet<CompanyDataLogin[]>('/auth/companies', { token })
}

export const GetCompany = async (id: string) => {
  return await ApiGet<Company>(`/auth/companies/${id}`, {})
}
