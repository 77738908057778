import React from 'react'
import {
  CAlert,
  CCol,
  CContainer,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { DialogProps, DialogType } from '../../contexts/DialogContext'
import { TiWarning } from 'react-icons/ti'
import { useUser } from '../../contexts/AuthContext'

interface IDialog {
  visible: boolean
  dialogType: DialogType
  dialogProps?: DialogProps
  onClose: (response: boolean) => void
}

const Dialog = (props: IDialog) => {
  const { user } = useUser()

  const getTitle = (dialogType: DialogType) => {
    switch (dialogType) {
      case 'confirm':
        return 'Confirma?'
      case 'delete':
        return 'Confirma exclusão?'
      default:
        return 'Informação'
    }
  }

  const getMessage = () => {
    switch (props.dialogType) {
      case 'delete':
        return 'Confirma a exclusão do registro?'
      default:
        return props.dialogProps?.message
    }
  }

  const handleClick = (click: boolean) => {
    props.onClose(click)
  }

  return (
    <CModal
      visible={props.visible}
      onClose={() => props.onClose(false)}
      alignment={'center'}
    >
      <CModalHeader closeButton className="d-flex">
        <CContainer>
          <CRow className="lead fw-bold">
            <CCol>{getTitle(props.dialogType)}</CCol>
          </CRow>
        </CContainer>
      </CModalHeader>
      <CModalBody>
        <CContainer>
          <CRow>
            <CCol>{getMessage()}</CCol>
          </CRow>
          {props.dialogProps?.alert ? (
            <CRow className="mt-2 label-font-smaller">
              <CCol>
                <CAlert color="warning">{props.dialogProps?.alert}</CAlert>
              </CCol>
            </CRow>
          ) : null}
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CContainer>
          <CRow>
            <CCol className="d-flex label-font-smaller">{`Usuário: ${user?.name}`}</CCol>
          </CRow>
          {props.dialogType === 'delete' ? (
            <CRow className="m-2">
              <CCol className="text-danger d-flex align-items-center label-font-smaller">
                <TiWarning size={'23px'} className="me-1" />
                {`Esta operação não poderá ser desfeita posteriomente.`}{' '}
              </CCol>
            </CRow>
          ) : null}
          <CRow>
            <CCol className="d-flex justify-content-end">
              <CLoadingButton
                className="me-2"
                color={'primary'}
                onClick={() => handleClick(true)}
              >{`Confirmar`}</CLoadingButton>
              <CLoadingButton
                onClick={() => handleClick(false)}
                variant="outline"
              >{`Cancelar`}</CLoadingButton>
            </CCol>
          </CRow>
        </CContainer>
      </CModalFooter>
    </CModal>
  )
}

export default Dialog
