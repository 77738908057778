import axios, { AxiosRequestConfig } from 'axios'
import api from './axiosInstance'
import { CompanyDataLogin } from '../types/Company.Types'

export interface IFetchApiData<T> {
  data: T | null
  error: string | null
  errorFields?: T
  message?: string
}

export type IFetchApiList<T> = {
  skip: number
  take: number
  count: number
  total?: number
  list: T[]
}

export type IFetchApiFormData = {
  key: string
  value?: any
}

const getCompanyId = () => {
  const data = localStorage.getItem('company')

  if (data) {
    return JSON.parse(data) as CompanyDataLogin
  }

  return null
}

async function RequestApi<T>(
  url: string,
  options: AxiosRequestConfig,
): Promise<IFetchApiData<T>> {
  try {
    const companyId = getCompanyId()

    const res = await api({
      url,
      ...options,
      ...(companyId
        ? { headers: { ...options.headers, companyId: companyId.id } }
        : {}),
    })

    if (res.status >= 200 && res.status < 400) {
      return {
        data: res.data,
        error: null,
        ...(res.data && res.data.message ? { message: res.data.message } : {}),
      }
    } else if (res.status >= 400 && res.status < 500) {
      return {
        data: null,
        error:
          res.data.error ||
          `Erro inesperado. Contacte o suporte. Cód.: ${res.status}`,
        ...(res.data && res.data.errorFields
          ? { errorFields: res.data.errorFields }
          : {}),
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        data: null,
        error: error.message,
      }
    } else {
      console.log('Error useRequest ===', error)

      return {
        data: null,
        error: 'Erro desconhecido.',
      }
    }
  }

  return {
    data: null,
    error: 'Sem dados.',
  }
}

export async function ApiGet<T>(
  endpoint: string,
  params?: any,
): Promise<IFetchApiData<T>> {
  return await RequestApi(endpoint, {
    method: 'get',
    params,
  })
}

export async function ApiPost<T>(
  endpoint: string,
  data?: any,
  timeout?: number,
): Promise<IFetchApiData<T>> {
  return await RequestApi(endpoint, {
    method: 'post',
    data,
    timeout,
  })
}

export async function ApiPostFormData<T>(
  endpoint: string,
  data: IFetchApiFormData[],
  timeout?: number,
): Promise<IFetchApiData<T>> {
  const formData = new FormData()

  data.forEach((item) => {
    formData.append(item.key, item.value)
  })

  return await RequestApi(endpoint, {
    method: 'post',
    data: formData,
    timeout,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function ApiPut<T>(
  endpoint: string,
  data?: any,
): Promise<IFetchApiData<T>> {
  return await RequestApi(endpoint, {
    method: 'put',
    data,
  })
}

export async function ApiPatch<T>(
  endpoint: string,
  data?: any,
): Promise<IFetchApiData<T>> {
  return await RequestApi(endpoint, {
    method: 'patch',
    data,
  })
}

export async function ApiDelete<T>(
  endpoint: string,
  params?: any,
): Promise<IFetchApiData<T>> {
  return await RequestApi(endpoint, {
    method: 'delete',
    params,
  })
}
