import { toast, ToastOptions } from 'react-toastify'

export const useToast = () => {
  const options: ToastOptions = { theme: 'dark', position: 'top-right' }

  return {
    toastDefault: (msg: string | null) => {
      if (null) return
      toast(msg, options)
    },
    toastError: (msg: string | null) => {
      if (null) return
      toast.error(msg, options)
    },
    toastWarn: (msg: string | null) => {
      if (null) return
      toast.warn(msg, options)
    },
    toastSuccess: (msg: string | null) => {
      if (null) return
      toast.success(msg, options)
    },
    toastInfo: (msg: string | null) => {
      if (null) return
      toast.info(msg, options)
    },
  }
}
