import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import AuthProvider from './contexts/AuthContext'
import { DialogProvider } from './contexts/DialogContext'
import ProtectRoute from './middlewares/RoutesProtect'
import CompanyProvider from './contexts/CompanyContext'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Register = React.lazy(
  () => import('./views/pages/auth/register/Register'),
)
const Login = React.lazy(() => import('./views/pages/auth/login/Login'))
const Logout = React.lazy(() => import('./views/pages/auth/logout/Logout'))
const VerifyEmail = React.lazy(
  () => import('./views/pages/auth/verifyEmail/VerifyEmail'),
)
const ForgotPassword = React.lazy(
  () => import('./views/pages/auth/forgotPassword/ForgotPassword'),
)
const ResetPassword = React.lazy(
  () => import('./views/pages/auth/resetPassword/ResetPassword'),
)
const ServiceOrderRenderPDF = React.lazy(() => import('./views/pages/os'))
const ReportsRenderPDF = React.lazy(() => import('./views/pages/reports'))
const PrivacyPolicy = React.lazy(
  () => import('./views/pages/termsSoftware/PrivacyPolicy'),
)
const UseTerms = React.lazy(
  () => import('./views/pages/termsSoftware/UseTerms'),
)

const MediasServiceOrder = React.lazy(
  () => import('./views/pages/open/MediasServiceOrder'),
)

class App extends Component {
  render(): JSX.Element {
    return (
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <ToastContainer />
          <AuthProvider>
            <CompanyProvider>
              <DialogProvider>
                <Routes>
                  <Route path="/auth/register" element={<Register />} />
                  <Route path="/auth/login" element={<Login />} />
                  <Route path="/auth/logout" element={<Logout />} />
                  <Route
                    path="/auth/verifyEmail/:verificationCode?"
                    element={<VerifyEmail />}
                  />
                  <Route
                    path="/auth/forgotPassword"
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="/auth/resetPassword/:resetToken"
                    element={<ResetPassword />}
                  />
                  <Route path="/os/:cso" element={<ServiceOrderRenderPDF />} />
                  <Route path="/reports/:name" element={<ReportsRenderPDF />} />
                  <Route
                    path="/terms/PrivacyPolicy"
                    element={<PrivacyPolicy />}
                  />
                  <Route path="/terms/UseTerms" element={<UseTerms />} />
                  <Route
                    path="/open/MediasServiceOrder/:id"
                    element={<MediasServiceOrder />}
                  />
                  <Route element={<ProtectRoute />}>
                    <Route path="/*" element={<DefaultLayout />} />
                  </Route>
                </Routes>
              </DialogProvider>
            </CompanyProvider>
          </AuthProvider>
        </Suspense>
      </BrowserRouter>
    )
  }
}

//https://api.oficinasis.com.br

export default App
